// @ts-nocheck
import React, {useState} from "react";
import HomePageAbout from "./HomePageAbout";
import dodgeImage from "../assets/dodge.jpg";

const AboutPage = () => {
  const stopUserSelect = {
    userSelect: "none",
  };
  // Array of sections
  const sections = ["Background", "Skills" ];
  // State to track the index of the selected section
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);

  // Function to handle click on the next button
  const handleNextClick = () => {
    setSelectedSectionIndex((prevIndex) => (prevIndex + 1) % sections.length);
  };
  return (
    <div className="sec3">
      <div className="home_about">
        <ul style={{ ...stopUserSelect }}>
          {sections.map((section, index) => (
            <li
              key={index}
              onClick={() => setSelectedSectionIndex(index)}
              className={index === selectedSectionIndex ? "active" : ""}
            >
              <h2 >
                {section}
              </h2>
              </li>
          ))}
        </ul>
      </div>
      <div
        className="home_last"
        // @ts-ignore
        style={{ ...stopUserSelect }}
      >
        <HomePageAbout section={sections[selectedSectionIndex]}/>
      </div>
      <div className="about_logo">
        <h1>Keizo Kato</h1>
      </div>
      <div className="about_button">
      <button onClick={handleNextClick}>next</button>
      </div>
    </div>
  );
};

export default AboutPage;
