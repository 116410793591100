import React from 'react';
import { useParallax } from "react-scroll-parallax";

const SkillBar = ({ skill, level }) => {
  const parallax = useParallax({
    startScroll: 200,
    endScroll: 900,
    translateX: ["-200", "0", "easeOut"],
  });
  return (
    <div className="skill">
      <span className="skill-name">{skill}</span>
      <span className="skill-bar">
        <span ref={parallax.ref} className="skill-level" style={{ width: `${level}%` }}></span>
      </span>
    </div>
  );
};

export default SkillBar;
