import React, { useState } from "react";

function HomePageAbout({ section }) {
  function render_skills() {
    return (
      <>
        <ul>
          <li>Project Management</li>
          <li>Computer Vision (OpenCV)</li>
          <li>Deep Learning (Pytorch)</li>
          <li>Web Development (React, Node.js, Django, FLASK, HTML, CSS)</li>
          <li>Mobile Development (React Native, Django)</li>
          <li>Database Management (PostgreSQL, MySQL)</li>
          <li>Version Control (Git)</li>
          <li>Containerization (Docker, Docker Compose)</li>
          <li>CI/CD Pipeline (Git Action)</li>
          <li>Deployment (Firebase, etc)</li>
          <li>Also, there is nothing I cannot develop in Java.</li>
        </ul>
      </>
    );
  }
  function render_awards() {
    return (
      <>
        <ul>
          <li>Dean’s List, University of British Columbia</li>
          <li>
            Deputy Vice-Chancellor Scholarship for International Students,
            University of British Columbia
          </li>
          <li>International Summit Scholarships, Simon Fraser University</li>
          <li>Deep Learning Specialization, DeepLearning.AI</li>
        </ul>
      </>
    );
  }

  function render_background() {
    return (
      <>
        <h1>Born and raised in {' '}
          <span 
            onClick={()=>alert("osaka")}
          >Osaka, Japan.</span>
        </h1>
        <h1>Arrived in Canada, 2019 in Victoria.</h1>
        <h1>
          Graduated from the University of British Columbia Okanagan with
          {' '} <span>
            Bsc(Hons) in Computer Science.
          </span> 
          
        </h1>
        <h1>
          Unique experience: {' '} <span>
          Coffee shop manager & project management in most of
          the school projects.
          </span>
        </h1>
        <h1>Extra Passions: {' '}<span>Music</span></h1>
      </>
    );
  }

  return (
    <div className="about_description">
      {section === "Background" && render_background()}
      {section === "Skills" && render_skills()}
      {/* {section === "Awards" && render_awards()} */}
    </div>
  );
}

export default HomePageAbout;
