/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { colors } from "../style/global_color";
// @ts-ignore
import github_icon from "../assets/github_icon.png"
const Header = () => {
  const stopUserSelect = {
    userSelect: "none",
  };
  return (
    <header className="thisIsHeader">
      <div className="logo">
        <Link to="/">
          <a>Keizo Kato</a>
        </Link>
      </div>
      <nav>
        <ul>
          <li
            // @ts-ignore
            style={stopUserSelect}
          >
            <NavLink className="nav-item" to="/">
              Home
            </NavLink>
          </li>
          <li
            // @ts-ignore
            style={stopUserSelect}
          >
            <NavLink className="nav-item" to="/projects">
              Projects
            </NavLink>
          </li>
          <li
            // @ts-ignore
            style={stopUserSelect}
          >
            {/* <NavLink className="nav-item" to="/about">
              About
            </NavLink> */}
            <a
              className="nav-item"
              href="https://github.com/Keizo410"
              target="_blank"
              rel="noreferrer"
              style={{ color: "black" }}
            >
              <img src={github_icon} alt="GitHub" style={{height:40, width:40}}/>
            </a>
          </li>
          <li
            // @ts-ignore
            style={{
              ...stopUserSelect,
              backgroundColor: "blue",
              padding: "1%",
            }}
          >
            <a
              className="nav-item"
              href="https://www.linkedin.com/in/keizok/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "white" }}
            >
              Linkedin
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
