/* eslint-disable react/jsx-no-undef */
import "./App.css";
import Header from "./components/Header";
import HomePage from "./components/HomePage";
import BlogPage from "./components/BlogPage";
import Projects from "./components/Projects";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { useState, useEffect } from "react";
import React from "react";
import AboutPage from "./components/AboutPage";

function App() {
  return (
    <Router>
      <div className="app">
        <div className="header">
          <Header />
        </div>
        <div className="content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            {/* <Route path="/blog" element={<BlogPage />} /> */}
            <Route path="/projects" element={<Projects />} />
            <Route path="/about" element={<AboutPage />}/>
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
