// @ts-nocheck
import React, { useState } from "react";
import { Card, CardBody, CardFooter, CardHeader } from "react-bootstrap";
import { colors } from "../style/global_color";
import projectsData from "../assets/projects.json";
import { Modal, Button } from "react-bootstrap";
import cameraImage from "../assets/camera2.png";
import dodgeImage from "../assets/dodge.jpg"
const Projects = () => {

  const checkWeblink = (projectData) => {
    const jo = projectData;
    const weblinkLength = jo.weblink.length;
    if (weblinkLength > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkYoutubeLink = (projectData) => {
    const jo = projectData;
    const ytlinkLength = jo.youtubeLink.length;
    if (ytlinkLength>0){
      return true;
    } else{
      return false;
    }
  }

  const render_project = (projectData) => {

    return (
      <div className="item">
        <div className="text">
          <h1 className="bigTitle">{projectData.name}</h1>
          <p>
            <h3 className="title">Overview: </h3>
            <span className="exp">{projectData.description}</span>
            <h3 className="title">Problem Statement: </h3>
            <span className="exp">{projectData.problemStatement}</span>
            <br></br>
            <h3 className="title">Technologies Used:</h3>
            <span className="exp">{projectData.tool}</span>
            <h3 className="title">Key Achievement: </h3>
            <span className="exp">{projectData.keyAchievement}</span>
          </p>
        </div>
        <div className="showcase">
          {checkYoutubeLink(projectData) ? (<iframe
            src={`https://www.youtube.com/embed/${projectData.youtubeLink}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="Embedded YouTube"
            frameBorder="0"
            allowFullScreen
            style={{ width: "100%", height: "100%" }}
          ></iframe>) : (            <img
              src={cameraImage}
              alt="pic"
            />)}
        </div>
        {checkWeblink(projectData) ? ( // Check if weblink exists
          <div className="demo-button">
            <Button href={projectData.weblink} target="_blank">
              Visit Code
            </Button>
          </div>
        ) : (
          <div className="demo-button">
            <Button
              href={projectData.weblink}
              target="_blank"
              disabled={true}
              style={{
                pointerEvents: "none",
                backgroundColor: "gray",
                border: "none",
              }}
            >
              Visible upon request
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      {projectsData.projects.map((project, index) => (
        <div key={index}>{render_project(project)}</div>
      ))}
    </div>
  );
};

export default Projects;
